// Generated by Framer (d5ca742)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const serializationHash = "framer-VJYF2"

const variantClassNames = {hD0JKtw1w: "framer-v-asd7cr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transition2 = {delay: 0.3, duration: 1.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 23}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "hD0JKtw1w", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-asd7cr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hD0JKtw1w"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1g4hw6v"} data-border layoutDependency={layoutDependency} layoutId={"Id0sfr08n"} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32}}><MotionDivWithFX __framer__loop={animation} __framer__loopEffectEnabled __framer__loopRepeatDelay={0} __framer__loopRepeatType={"loop"} __framer__loopTransition={transition2} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={"framer-18bbjyy"} data-border layoutDependency={layoutDependency} layoutId={"qRQ52Yct4"} style={{"--border-bottom-width": "1px", "--border-color": "rgb(34, 34, 34)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 32, borderBottomRightRadius: 32, borderTopLeftRadius: 32, borderTopRightRadius: 32}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-VJYF2.framer-whoqg8, .framer-VJYF2 .framer-whoqg8 { display: block; }", ".framer-VJYF2.framer-asd7cr { height: 54px; position: relative; width: 31px; }", ".framer-VJYF2 .framer-1g4hw6v { flex: none; height: 48px; left: calc(50.00000000000002% - 26px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 48px / 2); width: 26px; }", ".framer-VJYF2 .framer-18bbjyy { flex: none; height: 10px; left: calc(50.00000000000002% - 10px / 2); overflow: visible; position: absolute; top: 8px; width: 10px; }", ".framer-VJYF2[data-border=\"true\"]::after, .framer-VJYF2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 54
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerPsE2hOh7F: React.ComponentType<Props> = withCSS(Component, css, "framer-VJYF2") as typeof Component;
export default FramerPsE2hOh7F;

FramerPsE2hOh7F.displayName = "scroll up";

FramerPsE2hOh7F.defaultProps = {height: 54, width: 31};

addFonts(FramerPsE2hOh7F, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})